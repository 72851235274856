import {addLoader, removeLoader} from "./loader";

function serialize(form) {
    if (!form || form.nodeName !== "FORM") {
        return;
    }

    const q = [];
    let i, j;
    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
        if (form.elements[i].name === "") {
            continue;
        }
        switch (form.elements[i].nodeName) { 
            case 'INPUT':
                switch (form.elements[i].type) {
                    case 'text':
                    case 'tel':
                    case 'email':
                    case 'date':
                    case 'number':
                    case 'hidden':
                    case 'password':
                    case 'button':
                    case 'reset':
                    case 'submit':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                    case 'checkbox':
                    case 'radio':
                        if (form.elements[i].checked) {
                            q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        }
                        break;
                    case 'file':
                        break;
                }
                break;
            case 'TEXTAREA':
                q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                break;
            case 'SELECT':
                switch (form.elements[i].type) {
                    case 'select-one':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                    case 'select-multiple':
                        for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                            if (form.elements[i].options[j].selected) {
                                q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].options[j].value)}`);
                            }
                        }
                        break;
                }
                break;
            case 'BUTTON':
                switch (form.elements[i].type) {
                    case 'reset':
                    case 'submit':
                    case 'button':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                }
                break;
        }
    }
    return q.join("&").replace(/%20/g, '+');
}

export function initForm(formContainer) {

    const container = document.getElementById(formContainer);

    if (container) {
        const contactForm = container.querySelector('form');
        contactForm.addEventListener('submit', e => {
            e.preventDefault();
            const submitBtn = contactForm.querySelector("button[type=submit]");

            addLoader(submitBtn);

            setTimeout(() => removeLoader(submitBtn), 10000);
            contactForm.classList.add('sending');

            const XHR = new XMLHttpRequest();
            const formData = serialize(contactForm);

            // Define what happens
            XHR.onreadystatechange = function () {
                if (this.readyState === this.DONE) {
                    if (XHR.onreadystatechange) {
                        XHR.onreadystatechange = null;
                        const json = JSON.parse(this.responseText);

                        if (json.status !== 'ok') {
                            const errorText = contactForm.getAttribute('data-error-message');
                            alert(errorText);
                        } else {
                            const successPage = contactForm.getAttribute('data-success-url');
                            if (successPage) {
                                window.location = successPage;
                            }
                        }
                    }
                }
            };
            // Set up our request
            XHR.open('POST', '/umbraco/api/mail/sendmultinestedcontent', true);
            // Add the required HTTP header for form data POST requests
            XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            // Finally, send our data.
            XHR.send(formData);
        });
    }
}
